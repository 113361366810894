@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro");

html,
body,
div {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    font: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    font: inherit;
    font-weight: bold;
}

a,
p,
ul,
ol,
li,
img,
span,
strong,
em {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    font: inherit;
}

table,
tbody,
tfoot,
thead,
tr,
th,
td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    font: inherit;
}

applet,
object,
iframe,
blockquote,
pre,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
font,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
caption,
fieldset,
form,
label,
legend {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    font: inherit;
}

article,
aside,
figure,
footer,
header,
hgroup,
section {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    font: inherit;
}

canvas,
details,
embed,
figcaption,
menu,
nav,
output,
ruby,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    font: inherit;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

b,
strong {
    font-weight: bold;
}

em,
i {
    font-style: italic;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

:focus {
    outline: 0;
}

ins {
    text-decoration: none;
}

del {
    text-decoration: line-through;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

.grid-1,
.grid-2,
.grid-3,
.grid-4,
.grid-5,
.grid-6,
.grid-7,
.grid-8,
.grid-9,
.grid-10,
.grid-11,
.grid-12,
.grid-13,
.grid-14,
.grid-15,
.grid-16,
.grid-17,
.grid-18,
.grid-19,
.grid-20,
.grid-21,
.grid-22,
.grid-23,
.grid-24 {
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
}

.container-12 .block .omega-grid,
.container-16 .block .omega-grid,
.container-24 .block .omega-grid {
    position: relative;
    margin-left: -10px;
    margin-right: -10px;
}

.clearfix:after {
    font-size: 0;
}

#skip-link {
    left: 50%;
    margin-left: -5.25em;
    margin-top: 0;
    position: absolute;
    width: auto;
    z-index: 50;
}

#skip-link a,
#skip-link a:link,
#skip-link a:visited {
    background: #444;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    display: block;
    padding: 1px 10px 2px 10px;
    text-decoration: none;
    -khtml-border-radius: 0 0 10px 10px;
    -moz-border-radius: 0 0 10px 10px;
    -o-border-radius: 0 0 10px 10px;
    -webkit-border-top-left-radius: 0;
    -webkit-border-top-right-radius: 0;
    -webkit-border-bottom-left-radius: 10px;
    -webkit-border-bottom-right-radius: 10px;
    border-radius: 0 0 10px 10px;
}

#skip-link a:hover,
#skip-link a:active,
#skip-link a:focus {
    outline: 0;
}

.inline li,
.tabs li,
.breadcrumb li {
    list-style: none;
    display: inline;
    margin-right: 0.5em;
}

.item-list li {
    list-style: none;
}

.pager li {
    display: inline;
    margin-right: 0.25em;
}

img {
    vertical-align: bottom;
}

.field-label-inline .field-label,
.field-label-inline .field-items,
.field-label-inline .field-item {
    display: inline;
}

::-moz-focus-inner {
    border: 0;
    padding: 0;
}

input[type="search"]::-webkit-search-decoration {
    display: none;
}

input,
button,
select,
textarea {
    margin: 0;
    vertical-align: middle;
}

button,
input[type="reset"],
input[type="button"] {
    -webkit-appearance: none;
    -moz-border-radius: 11px;
    -webkit-border-radius: 11px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
    border-radius: 11px;
    /* background: #ddd url(../../../all/themes/omega/omega/images/button.png) repeat-x; */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff), color-stop(1, #ddd));
    background: -moz-linear-gradient(top center, #fff 0%, #ddd 100%);
    border: 1px solid;
    border-color: #ddd #bbb #999;
    cursor: pointer;
    color: #333;
    font: bold 12px/1.2 Arial, sans-serif;
    outline: 0;
    overflow: visible;
    padding: 3px 10px 4px;
    text-shadow: #fff 0 1px 1px;
    width: auto;
}


textarea,
select,
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"] {
    border-radius: 0;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid #cfcfcf;
    color: #545454;
    outline: 0;
    padding: 8px 5px;
    font-size: 16px;
    font-family: Arial, sans-serif;
    width: 100%;
    margin: 3px 0
}

input.placeholder_text,
textarea.placeholder_text,
input:-moz-placeholder,
textarea:-moz-placeholder {
    color: #888;
}

::-webkit-input-placeholder {
    color: #888;
}

button:focus,
button:active,
input:focus,
input:active,
select:focus,
select:active,
textarea:focus,
textarea:active {
    -moz-box-shadow: #06f 0 0 7px;
    -webkit-box-shadow: #06f 0 0 7px;
    box-shadow: #06f 0 0 7px;
    z-index: 1;
}

input[type="file"]:focus,
input[type="file"]:active,
input[type="radio"]:focus,
input[type="radio"]:active,
input[type="checkbox"]:focus,
input[type="checkbox"]:active {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

select[disabled],
textarea[disabled],
input[type="date"][disabled],
input[type="datetime"][disabled],
input[type="datetime-local"][disabled],
input[type="email"][disabled],
input[type="month"][disabled],
input[type="number"][disabled],
input[type="password"][disabled],
input[type="search"][disabled],
input[type="tel"][disabled],
input[type="text"][disabled],
input[type="time"][disabled],
input[type="url"][disabled],
input[type="week"][disabled] {
    background-color: #eee;
}

button[disabled],
input[disabled],
select[disabled],
select[disabled] option,
select[disabled] optgroup,
textarea[disabled] {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    color: #888;
    cursor: default;
}

textarea,
select[size],
select[multiple] {
    height: auto;
}

@media (-webkit-min-device-pixel-ratio:0) {
    select {
        /* background-image: url(../../../all/themes/omega/omega/images/select-arrow.gif); */
        background-repeat: no-repeat;
        background-position: right center;
        padding-right: 20px;
    }

    select[size],
    select[multiple] {
        background-image: none;
        padding: 0;
    }
}

textarea {
    min-height: 40px;
    overflow: auto;
    resize: vertical;
    width: 100%;
}

optgroup {
    color: #000;
    font-style: normal;
    font-weight: normal;
}

.ie6-button,
* html button {
    /* background: #ddd url(../../../all/themes/omega/omega/images/button.png) repeat-x; */
    border: 1px solid;
    border-color: #ddd #bbb #999;
    cursor: pointer;
    color: #333;
    font: bold 12px/1.2 Arial, sans-serif;
    padding: 2px 10px 0px;
    overflow: visible;
    width: auto;
}

* html button {
    padding-top: 1px;
    padding-bottom: 1px;
}

.ie6-input,
* html textarea,
* html select {
    background: #fff;
    border: 1px solid;
    border-color: #848484 #c1c1c1 #e1e1e1;
    color: #000;
    padding: 2px 3px 1px;
    font-size: 13px;
    font-family: Arial, sans-serif;
    vertical-align: top;
}

* html select {
    margin-top: 1px;
}

.placeholder_text,
.ie6-input-disabled,
.ie6-button-disabled {
    color: #888;
}

.ie6-input-disabled {
    background: #eee;
}

body {
    font: 13px/1.5 Verdana, Helvetica, Arial, sans-serif;
}

a:link,
a:visited {
    color: #0062A0;
    text-decoration: underline;
}

a:hover {
    color: #202124;
    text-decoration: none;
}

a:active {
    color: #202124;
    text-decoration: none;
    color: #000;
}

hr {
    margin: 0;
    padding: 0;
    border: none;
    height: 1px;
    background: #5294c1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    letter-spacing: -0.05em;
    font-family: Georgia, 'Times New Roman', serif;
}

h1 {
    font-size: 36px;
}

h2 {
    font-size: 28px;
}

h1.site-name,
h2.site-name {
    font-size: 36px;
}

h2.block-title {
    font-size: 18px;
    margin: 0;
}

h2.node-title {
    font-size: 28px;
}

h3 {
    font-size: 21px;
}

h4 {
    font-size: 19px;
}

h5 {
    font-size: 17px;
}

h6 {
    font-size: 15px;
}

ol {
    list-style: decimal;
}

ul {
    list-style: disc;
}

ul ul,
ol ol {
    padding-top: 0.5em;
}

.node ol,
.node ul {
    padding-left: 1.2em;
}

.node ul.inline,
.node ol.inline {
    padding-left: 0;
}

p,
dl,
hr,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
ul,
pre,
table,
address,
fieldset {
    margin-bottom: 20px;
}

dt,
.admin h3 {
    font-size: 18px;
    margin: 0;
}

dd {
    padding-left: 10px;
    font-size: 0.9em;
    font-style: italic;
    margin-bottom: 20px;
}

.logo-img {
    float: left;
    margin-right: 20px;
}

.site-name-slogan {
    float: left;
}

.site-name {
    margin-top: 20px;
}

.site-name a {
    text-decoration: none;
}

.site-name,
.site-slogan {
    margin-bottom: 0;
    line-height: 1em;
}

.site-slogan {
    font-weight: normal;
}

.menu {
    margin: 0;
    padding: 0;
}

.menu li {
    list-style: none;
}

.form-actions input {
    margin-right: 0.5em;
}

.form-actions input:hover {
    background: #FFFFFF;
}

label {
    font-weight: bold;
}

.item-list .pager {
    padding: 0;
    margin: 0;
}

.item-list .pager li,
.item-list .pager ul li {
    margin: 0;
    padding: 0;
    float: left;
}

.item-list .pager {
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 10px;
    text-align: left;
}

.pager li a {
    display: inline-block;
}

.pager li.pager-ellipsis,
.pager li.pager-current,
.pager li a {
    padding: 4px 8px;
    text-decoration: none;
}

.pager li.pager-current {
    padding: 4px 8px;
    font-weight: bold;
}

.pager li.pager-first a,
.pager li.pager-previous a,
.pager li.pager-next a,
.pager li.pager-last a {
    width: 50px;
    text-align: center;
    padding: 4px 0;
}

.pager li.pager-next {
    position: absolute;
    top: 0;
    right: 50px;
}

.pager li.pager-last,
.pager li.pager-next.last {
    position: absolute;
    top: 0;
    right: 0;
    border-right: 0;
}

.pager li.pager-ellipsis,
.pager li.pager-current,
.pager li a {
    color: #1a1a1a;
}

.pager li {
    border-right: 1px solid;
    border-color: #ddd #bbb #999;
    color: #333;
    font: bold 12px/1.2 Arial, sans-serif;
    outline: 0;
}

.pager li.pager-current {
    /* background: #ddd url(../../../all/themes/omega/omega/images/button.png) repeat-x; */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #1a1a1a), color-stop(1, #5a5a5a));
    background: -moz-linear-gradient(top center, #1a1a1a 0%, #5a5a5a 100%);
    color: #FFF;
    outline: 0;
}

.pager li.pager-next {
    border-left: 1px solid;
    border-color: #ddd #bbb #999;
}

.pager li a {
    /* background: #ddd url(../../../all/themes/omega/omega/images/button.png) repeat-x; */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff), color-stop(1, #ddd));
    background: -moz-linear-gradient(top center, #fff 0%, #ddd 100%);
    cursor: pointer;
}

.pager li a:hover {
    background: #FFF;
    color: #454545;
}

.pager {
    background: #ebebeb;
    border: 1px solid;
    border-color: #ddd #bbb #999;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
}

.pager li.first,
.pager li.first a,
.pager li.first a:hover {
    border-radius: 5px 0 0 5px;
    -moz-border-radius: 5px 0 0 5px;
    -webkit-border-radius: 5px 0 0 5px;
}

.pager li.pager-last,
.pager li.pager-last a,
.pager li.pager-last a:hover {
    border-radius: 0 5px 5px 0;
    -moz-border-radius: 0 5px 5px 0;
    -webkit-border-radius: 0 5px 5px 0;
}

table {
    border: 1px solid #CCC;
    width: 100%;
}

thead th,
th {
    background: #f7f7f7;
    border-bottom: 1px solid #ccc;
    color: #333;
}

td,
th {
    padding: 4px 8px;
    vertical-align: middle;
}

th a,
th a:link,
th a:visited {
    text-decoration: none;
}

th a {
    display: block;
}

th a img {
    margin: 0 0 0 5px;
}

th.active {
    background: #DDD;
    border-left: 1px solid #CCC;
    border-right: 1px solid #CCC;
}

tr.even td {
    background: #F1F5FA;
}

tr.odd td {
    background: #fff;
}

td.active {
    border-left: 1px solid #CCC;
    border-right: 1px solid #CCC;
}

tr.even td.active {
    background: #e5e9ed;
}

tr.odd td.active {
    background: #f2f2f2;
}

tr:hover td,
tr.even:hover td.active,
tr.odd:hover td.active {
    background: #FFFEEE;
}

td ul.links {
    margin-bottom: 0;
}

.node-links ul li a,
.comment-links ul li a {
    text-transform: lowercase;
    display: block;
    float: left;
    text-decoration: none;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
    margin: 0 0.5em 0.5em 0;
    vertical-align: middle;
    -webkit-appearance: none;
    -moz-border-radius: 11px;
    -webkit-border-radius: 11px;
    border-radius: 11px;
    /* background: #ddd url(../../../all/themes/omega/omega/images/button.png) repeat-x; */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff), color-stop(1, #ddd));
    background: -moz-linear-gradient(top center, #fff 0%, #ddd 100%);
    border: 1px solid;
    border-color: #ddd #bbb #999;
    cursor: pointer;
    color: #333;
    font: bold 12px/1.2 Arial, sans-serif;
    outline: 0;
    overflow: visible;
    padding: 4px 10px 3px;
    text-shadow: #fff 0 1px 1px;
    width: auto;
}

.node-links ul li a:hover,
.comment-links ul li a:hover {
    background: #FFF;
}

.tips,
.description {
    font-size: 10px;
    color: #6a6a6a;
}

* {}

body {
    font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
    background-color: #000;
    color: #fff;
    font-size: 14px;
}

a:link,
a:visited {
    color: #6fa201;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

h1,
h2,
h3 {
    margin-bottom: 10px;
}

h1.title{
    font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
    font-size: 18px;
    text-transform: uppercase;
    color: #6fa201;
    letter-spacing: .3px;
    font-weight: bold;
}


.content h2 {
    font-family: 'Source Sans Pro',
    Helvetica,
    Arial,
    sans-serif;
    font-size: 14px;
    font-weight: normal;
    text-transform: uppercase;
    color: #6fa201;
    letter-spacing: .2px;


}

#main-menu {
    display: inline;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    position: relative;
}

header {}

header>div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: right;
    -ms-flex-pack: right;
    justify-content: right;
}

header>div:first-child {
    min-height: 85px;
}

#main-menu {
    list-style-type: none;
    right: 0px;
    visibility: hidden;
    display: none;
}

#main-menu li,
#main-menu li.leaf {
    list-style-type: none;
    list-style-image: none;
    padding: .5em .5em;
    margin: 0;
}

.mm-menu.mm-offcanvas {
    width: 200px
}

html.mm-right.mm-opening .mm-slideout {
    -webkit-transform: translate(-200px, 0);
    -ms-transform: translate(-200px, 0);
    transform: translate(-200px, 0);
    -webkit-transform: translate3d(-200px, 0, 0);
    transform: translate3d(-200px, 0, 0)
}

body>nav:not(.mm-menu) {
    display: none;
}

.hamburger,
.hamburger:visited {
    display: block;
    font-size: 22px;
    float: right;
    background-color: #505050;
    color: #c5c5c5;
    border-radius: 5px;
    padding: 0 .5em;
}

.hamburger:hover,
.hamburger:active {
    color: #fff;
}

header a:link,
header a:visited {
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
}

header a.active,
header a:hover {
    color: #6fa201;
}

#region-content {
    background-color: #fff;
    color: #000;
}

#region-content .region-inner,
#region-sidebar-second .region-inner {
    padding: 15px;
}

#zone-postscript h2 {
    font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
    font-size: 14px;
    text-transform: uppercase;
}

#region-sidebar-second,
#region-sidebar-second h2 {
    color: #000;
}

.not-front #zone-content-wrapper,
.not-front #zone-preface-wrapper {
    background-color: #fff;
}

.not-front #zone-preface-wrapper {}

.social-list,
.social-list li {
    list-style-type: none;
}

.social-list li {
    display: inline-block;
}

.copyright {
    font-size: 11px;
}

.context-services tr td:nth-child(2) {
    text-align: right;
}

.drop-shadow {
    -webkit-box-shadow: 2px 2px 6px 0px #666;
    box-shadow: 2px 2px 6px 0px #666;
}

.left {
    float: left;
    margin: 0 20px 20px 0;
}

.right {
    float: right;
    margin: 0px 0px 20px 20px;
}

.product {
    width: 100%;
    min-height: 400px;
    float: none;
    display: block;
    margin: 0px;
}

.content img {
    width: auto !important;
    max-width: 100% !important;
    height: auto !important;
    display: block;
}

.webform-client-form input[type='text'],
.webform-client-form input[type='email'],
.webform-client-form textarea {
    max-width: 100%;
}

#albums li {
    float: left;
    margin-right: 40px;
    margin-bottom: 40px;
}

#albums li p {
    margin-top: 5px;
}

.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
    outline: none;
}

.slides,
.flex-control-nav,
.flex-direction-nav {
    margin: 0;
    padding: 0;
    list-style: none;
}

.flexslider {
    margin: 0;
    padding: 0;
}

.flexslider .item-list ul>li {
    display: none;
    -webkit-backface-visibility: hidden;
}

.flexslider .slides img {
    width: 100%;
    height: auto;
    display: block;
}

.flex-pauseplay span {
    text-transform: capitalize;
}

.slides:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

html[xmlns] .slides {
    display: block;
}

* html .slides {
    height: 1%;
}

.no-js .slides>li:first-child {
    display: block;
}

.flexslider {
    margin: 0 0 0px;
    zoom: 1;
}

.flex-viewport {
    max-height: 2000px;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    transition: all 1s ease;
}

.loading .flex-viewport {
    max-height: 300px;
}

.flexslider .slides {
    zoom: 1;
}

.item-list ul li,
.item-list ul {
    margin: 0px;
    padding: 0px;
}

.carousel li {
    margin-right: 5px
}

.flex-direction-nav {
    height: 0;
}

.flex-direction-nav a {
    width: 30px;
    height: 30px;
    margin: -20px 0 0;
    display: block;
    background: url('../images/bg_direction_nav.png') no-repeat 0 0;
    position: absolute;
    top: 50%;
    z-index: 10;
    cursor: pointer;
    text-indent: -9999px;
    opacity: 0;
    -webkit-transition: all .3s ease;
}

.flex-direction-nav .flex-next {
    background-position: 100% 0;
    right: -36px;
}

.flex-direction-nav .flex-prev {
    left: -36px;
}

.flexslider:hover .flex-next {
    opacity: 0.8;
    right: 5px;
}

.flexslider:hover .flex-prev {
    opacity: 0.8;
    left: 5px;
}

.flexslider:hover .flex-next:hover,
.flexslider:hover .flex-prev:hover {
    opacity: 1;
}

.flex-direction-nav .flex-disabled {
    opacity: .3 !important;
    filter: alpha(opacity=30);
    cursor: default;
}

.flex-control-nav {
    width: 100%;
    position: absolute;
    bottom: -40px;
    text-align: center;
}

.flex-control-nav li {
    margin: 0 6px;
    display: inline-block;
    zoom: 1;
}

.flex-control-paging li a {
    width: 11px;
    height: 11px;
    display: block;
    background: #666;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    text-indent: -9999px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}

.flex-control-paging li a:hover {
    background: #333;
    background: rgba(0, 0, 0, 0.7);
}

.flex-control-paging li a.flex-active {
    background: #000;
    background: rgba(0, 0, 0, 0.9);
    cursor: default;
}

.flex-control-thumbs {
    margin: 5px 0 0;
    position: static;
    overflow: hidden;
}

.flex-control-thumbs li {
    width: 25%;
    float: left;
    margin: 0;
}

.flex-control-thumbs img {
    width: 100%;
    display: block;
    opacity: .7;
    cursor: pointer;
}

.flex-control-thumbs img:hover {
    opacity: 1;
}

.flex-control-thumbs .flex-active {
    opacity: 1;
    cursor: default;
}

@media screen and (max-width:860px) {
    .flex-direction-nav .flex-prev {
        opacity: 1;
        left: 0;
    }

    .flex-direction-nav .flex-next {
        opacity: 1;
        right: 0;
    }
}


.tipsy {
    padding: 5px;
    font-size: 10px;
    opacity: 0.8;
    filter: alpha(opacity=80);
    background-repeat: no-repeat;
    /* background-image: url(../../themes/guillermos_omega/images/tipsy.html); */
}

.tipsy-inner {
    padding: 5px 8px 4px 8px;
    background-color: black;
    color: white;
    max-width: 200px;
    text-align: center;
}

.tipsy-inner {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
}

.tipsy-north {
    background-position: top center;
}

.tipsy-south {
    background-position: bottom center;
}

.tipsy-east {
    background-position: right center;
}

.tipsy-west {
    background-position: left center;
}

.button, input[type=submit].button {
    background-color: #6fa201 !important;
    color: white !important;
    text-decoration: none !important;
    padding: .75em 2em;
    display: inline-block;
    font-family: sans-serif;
    text-transform: uppercase;
    transition: .5s all linear;
    border-radius: none;
    border: none;
}
.button:hover, input[type=submit].button:hover {
    background-color: maroon;
}


/* #footer-ad {
    box-sizing: border-box;
    width: 100%;
    background-color:#333;
    border-radius: 8px;
    padding: 10px;
    background-image: url("../images/footer-ad.jpg");
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 30%;

} */